<template>
    <div>
        <div class="container-fluid">
            <div class="mt-4 page-header min-height-300 border-radius-xl"
                :style="{ backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')', backgroundPositionY: '50%' }">
                <span class="mask bg-gradient-success opacity-6"></span>
            </div>
            <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
                <div class="row gx-4">
                    <div class="col-auto">
                        <div class="avatar avatar-xl position-relative">
                            <img
                                v-if="member?.user?.avatar"
                                :src="member?.user?.avatar"
                                :alt="member?.user?.name"
                                class="shadow-sm w-100 border-radius-lg"
                            />
                            <img
                                v-else
                                src="~@/assets/img/member-no-image.png"
                                :alt="member?.user?.name"
                                class="shadow-sm w-100 border-radius-lg"
                            />
                        </div>
                    </div>
                    <div class="col-auto my-auto">
                        <div class="h-100">
                            <h5 class="mb-1">{{ member?.user?.name || 'N/A' }}</h5>
                            <p class="mb-0 text-sm font-weight-bold">{{ member?.user?.username || 'N/A' }}</p>
                        </div>
                    </div>

                    <div class="mx-auto mt-3 col-md-12">
                        <div class="nav-wrapper position-relative end-0">
                            <ul class="p-1 bg-transparent nav nav-pills nav-fill">

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberInfo' }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/info.svg" alt="Info">
                                        <span class="ms-1">Info</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberReservations', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/reservation.svg" alt="Reservations">
                                        <span class="ms-1">Reservations ({{ member?.reservations_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberBookings', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/booking.svg" alt="Bookings">
                                        <span class="ms-1">Bookings ({{ member?.bookings_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberTickets', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/ticket.svg" width="20" alt="Events">
                                        <span class="ms-1">Tickets Order ({{ member?.tickets_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberBottleOrder', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/bottle-order.svg" width="20" alt="Bottle Order">
                                        <span class="ms-1">Bottles Order ({{ member?.bottle_order_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberJobs', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/career.svg" alt="Careers">
                                        <span class="ms-1">Careers ({{ member?.careers_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberGiftCards', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/gift-card.svg" width="25" alt="Gift Cards">
                                        <span class="ms-1">Gift Cards ({{ member?.gift_cards_count || 0 }})</span>
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link :to="{ name: 'MemberReviews', params: { id } }" class="px-0 py-1 mb-0 nav-link">
                                        <img src="~@/assets/img/icons/review.svg" alt="Reviews">
                                        <span class="ms-1">Reviews ({{ member?.reviews_count || 0 }})</span>
                                    </router-link>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="py-4 px-4 container-fluid">
            <router-view />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex'
import {
    faFacebook,
    faTwitter,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import setTooltip from '@/assets/js/tooltip.js'
import SoftAvatar from '@/components/SoftAvatar'
import setNavPills from '@/assets/js/nav-pills.js'

export default {
    props: ['id'],

    name: 'MemberProfileContainer',

    data() {
        return {
            showMenu: false,
            faFacebook,
            faTwitter,
            faInstagram,
        }
    },

    components: {
        SoftAvatar,
    },

    computed: {
        ...mapState({
            member: state => state.member.member,
        }),
    },

    beforeMount () {
        this.getMemberInfo(this.id)
    },

    mounted() {
        this.$store.state.isAbsolute = true
        setNavPills()
        setTooltip(this.$store.state.bootstrap)
    },

    beforeUnmount() {
        this.$store.state.isAbsolute = false
    },

    methods: {
        ...mapActions({
            getMemberInfo: `member/getMemberInfoAction`,
        }),
    },
}
</script>

<style lang="scss" scoped>
.nav-link {
    &.active {
        // box-shadow: none!important;
    }

    &.router-link-exact-active {
        background: #fff!important;
        box-shadow: 0px 1px 5px 1px #ddd;
        animation: 0.2s ease;
    }
}
</style>
